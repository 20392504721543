@import 'styles/mixins/vars-and-mixins';

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.react-tiny-popover-container {
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.2);
}

// react-select separator line
div[class$='IndicatorsContainer'] > span[class$='indicatorSeparator'] {
  display: none;
}

.ReactCollapse--collapse {
  transition: height 300ms;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms linear;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content.ReactModal__Content {
  transform: translate(-50%, -75%);
  transition: transform 250ms ease-in-out;
}
.ReactModal__Content.ReactModal__Content--after-open {
  transform: translate(-50%, -50%);
}

.sr-only {
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.render-error {
  $text-color: black;
  $background-color: $white;
  $border-color: $default-border-color;

  max-width: 600px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 25px;
  background-color: $background-color;
  border: 1px solid $border-color;
  border-radius: 0.25rem;
  box-shadow: 8px 8px 5px rgba(0, 0, 0, 0.1);

  > * + *:not(:last-child) {
    margin-bottom: 20px;
  }

  img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }

  h1 {
    color: $text-color;
  }

  code {
    display: block;
    padding: 8px 16px;
    border: 1px solid #dadce0;
    border-left: 4px solid #69a1ac;
    background-color: hsl(189.9, 28.8%, 85.3%);
    color: black;
  }

  hr {
    border-color: rgba(0, 0, 0, 0.3);
  }

  .buttons {
    display: flex;
  }

  .report-btn {
    @include secondary-btn;
    flex: 1;
    margin-left: 8px;
  }

  .back-btn {
    @include primary-btn;
    text-align: center;
    flex: 1;
    margin-right: 8px;
  }

  details {
    padding: 15px;
    background-color: #cbcbcbcc;
  }

  summary {
    cursor: pointer;
  }

  pre {
    white-space: pre-wrap;
  }

  p {
    color: $text-color;
    font-weight: bold;
  }
}

.pos {
  color: $pos;
}

.neg {
  color: $neg;
}

.change-cell {
  color: white;
  border-radius: $size-2;
  background-color: $neg;
  padding: 0 $spacing-sm 0 $spacing-sm;
  min-width: $size-56;
  display: flex;
  justify-content: flex-end;
}

.change-cell-pos {
  background-color: $pos;
}

.change-cell-neg {
  background-color: $neg;
}

.maintMessages {
  @include info-box;

  max-width: 840px;
  margin: 0 auto;
  padding: 10px 15px;

  p {
    margin-bottom: 0px;
  }

  button {
    float: right;
    text-decoration: underline;
    background-color: $pastel-blue;
    border: none;
  }

  &::before {
    content: $fa-var-info-circle;
    float: left;
    margin-top: -2px;
    margin-right: 25px;
    font-family: 'FontAwesome';
    font-size: 20px;
  }
}

.table-base {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  thead {
    tr {
      height: $size-40;
      th,
      td {
        font-weight: 600;
      }
    }
  }

  tbody tr:last-of-type {
    border: none;
  }

  tfoot {
    border-top: 1px solid $default-border-color;
  }

  tr {
    height: $size-40;
    border-bottom: 1px solid $default-border-color;
    box-sizing: border-box;
    width: 100%;

    &:hover {
      background-color: $table-hover-color;
    }

    th,
    td {
      vertical-align: middle;
    }

    th:first-child,
    td:first-child {
      padding-left: $spacing-md;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th:last-child,
    td:last-child {
      padding-right: $spacing-md;
    }

    th.align-right,
    td.align-right {
      text-align: right;
    }

    th.align-left,
    td.align-left {
      text-align: left;
    }

    th.align-center,
    td.align-center {
      text-align: center;
    }
  }
}

.table-news {
  max-width: 100vw;

  ul {
    li {
      height: $size-40;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-left: $spacing-md;
      padding-right: $spacing-md;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;

      b[role='columnheader'] {
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: flex-start;
      }

      b[role='columnheader']:first-of-type {
        width: 100px;
      }

      b[role='columnheader']:nth-child(2) {
        width: 128px;
      }
    }

    li[role='row'] {
      a {
        max-width: 500px;
        min-width: 400px;
      }
      @media (max-width: 700px) {
        a {
          min-width: 275px;
        }
      }
    }
    li[role='row'] button {
      display: flex;
      height: $size-40;
      padding-right: $spacing-md;
      padding-left: $spacing-md;
      align-items: center;

      &:hover {
        background-color: $table-hover-color;
      }

      [role='gridcell'] {
        padding: 0;
      }

      time {
        width: 100px;
      }

      .ticker {
        width: 128px;
        text-align: left;
      }
    }

    li.active {
      background: $table-hover-color;
    }
  }

  nav {
    display: none;
  }

  article {
    padding: $size-16 $size-24;
    border-left: 1px solid $default-border-color;

    > div {
      height: 140px !important;
    }

    a h3 {
      margin-top: 0;
      margin-bottom: $size-32;
      color: $default-link-color;
    }

    a h3::after {
      content: '→';
      margin-left: $size-8;
    }
  }

  /* Override oms components styling */
  @media (max-width: 40em) {
    flex-direction: column;

    article {
      border-left: none;
      padding: 16px 16px;
    }

    > div > ul > li[role='row'] {
      border-right: none;
    }
  }
}

.table-news:not(.withSidebar) {
  ul {
    li[role='rowheader'],
    li[role='row'] {
      white-space: nowrap;
      background-color: $white;

      &:hover {
        background-color: #f4f9ff;
      }

      b[role='columnheader']:first-of-type,
      time {
        width: 100px;
        padding: 0px;
      }
    }
  }

  > div > ul > li[role='row'] {
    border-right: none;
  }
}

.table-calendar {
  tr {
    th,
    td {
      text-align: left;
    }
    th:first-of-type,
    td:first-of-type {
      width: 120px;
    }
  }
  & + nav {
    display: none;
  }
  @media (max-width: 480px) {
    th:nth-child(2),
    td:nth-child(2) {
      width: 88px;
    }
  }
}

.table-investors {
  th:nth-child(2),
  td:nth-child(2) {
    text-align: right;
  }
}

.table-insiders {
  tr {
    th,
    td {
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.table-dividend {
  th:nth-child(2),
  td:nth-child(2) {
    text-align: right;
  }
}
.table-last-trades {
  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    text-align: right;
  }

  tfoot tr:last-child {
    border-bottom: none;
  }
}

.table-analysis-chart {
  @media (max-width: 480px) {
    margin: 0px 16px;
    width: calc(100vw - 32px);
  }
}

.table-analysis-chart button[type='submit'] {
  @include default-btn;
  color: $white;
  background-color: $trader-blue;
  &:hover {
    background: color($trader-blue blackness(25%));
    border-color: color($trader-blue blackness(25%));
  }
  @media (max-width: 480px) {
    margin-bottom: 16px;
  }
}

.table-company-info {
  td[colspan='2'] p {
    overflow-y: initial;
    padding: 8px;
  }

  caption {
    border: initial;
  }

  > div:last-of-type {
    border-top: none;
    article {
      border-left: none;
      p {
        text-align: left;
      }

      > img {
        max-width: initial;
        height: initial;
        max-width: 100px !important;
      }

      .logo {
        width: initial;
        height: initial;
        max-height: 100px !important;
        background: $white;
      }
    }
  }

  @media (max-width: 480px) {
    border: none;
    > div:first-of-type {
      table {
        border-right: none;
      }
    }
    > div:last-of-type {
      border-top: none;
      article {
        border-left: none;
        p {
          text-align: left;
        }
      }
    }
  }
}

.table-align-right {
  th:not(:first-of-type),
  td:not(:first-of-type) {
    text-align: right;
  }
}

.fictive-table {
  tbody tr {
    td:last-child {
      overflow: inherit;
    }
  }
}

.table-order-depth {
  /* might not be needed anymore */
  thead tr,
  tbody tr {
    th,
    td {
    }
    th:first-of-type,
    td:first-of-type,
    th:last-of-type,
    td:last-of-type {
      max-width: 40px;
    }
  }
  thead {
    tr th:nth-child(4) {
      text-align: center;
    }
  }
  tbody {
    tr td:nth-child(5) {
      padding-right: 12px;
    }
    tr td:nth-child(4) {
      padding-left: 12px;
    }
  }
}

.table-insider-trades,
.table-news--small {
  tr {
    th,
    td {
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    th:first-of-type,
    td:first-of-type {
      width: 120px;
    }
  }
  & + nav {
    display: none;
  }
}
@media (max-width: 767px) {
  .notification-bar {
    bottom: 4rem !important;
    left: -180% !important;
  }
}

.pagebreak {
  page-break-after: always;
  clear: both;
  position: relative;
}
